<template>
  <el-slider
    :min="min"
    :max="max"
    :step="step"
    :range="range"
    :show-input="showInput"
    :model-value="modelValue"
    @change="handleChange"
    @update:modelValue="handleModelValue"
    :aria-label="label"
    :format-tooltip="formatTooltip"
    :readonly="readonly"
    :class="{ readonly: readonly }"
  >
    <slot></slot>
  </el-slider>
</template>

<script setup lang="ts">
interface NiceSliderProps {
  modelValue: number | number[] | null
  label?: string | number
  disabled?: boolean
  showInput?: boolean
  range?: boolean
  min?: number
  max?: number
  step?: number
  formatTooltip?: (value: number | string) => string
  readonly?: boolean
}

const emit = defineEmits(["change", "update:modelValue"])

const props = withDefaults(defineProps<NiceSliderProps>(), {
  disabled: false,
  showInput: false,
  range: false,
  min: 0,
  max: 100,
  step: 1,
  readonly: false,
})

const handleChange = (value: number | number[]) => {
  emit("change", value)
}

const handleModelValue = (value: number | number[]) => {
  emit("update:modelValue", value)
}
</script>

<style>
.el-slider__runway {
  margin-top: 12px;
  margin-bottom: 12px;
}

.readonly {
  pointer-events: none;
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
</style>
