import QueryKeys from "@/integrations/query-keys"
import is24CustomerService from "@/integrations/services/is24-customer-service"
import { useQueryClient } from "@tanstack/vue-query"

const fetchOAuth2Token = async (portalId?: number) => {
  if (!portalId) {
    return null
  }

  const response = await is24CustomerService.getOAuth2AccessToken(portalId)

  if (response.isSuccessful2xx) {
    return response.data ?? null
  }

  return null
}

export const useFetchAuth2Token = () => {
  const queryClient = useQueryClient()

  const fetchToken = (portalId?: number) => {
    return queryClient.fetchQuery({
      queryKey: QueryKeys.oauth2.byPortalId(portalId),
      queryFn: () => fetchOAuth2Token(portalId),
    })
  }

  return {
    fetchToken,
  }
}
