export const QueryKeys = {
  integrations: {
    all: () => ["integrations"] as const,
  },
  memberships: {
    all: () => ["memberships"] as const,
    byMembership: (membershipId: string) => [...QueryKeys.memberships.all(), membershipId] as const,
  },
  orders: {
    all: () => ["orders"] as const,
    byIntegration: (integrationId: string) => [...QueryKeys.orders.all(), integrationId] as const,
    byIntegrationByEntityId: (integrationId: string, entityId: string) =>
      [...QueryKeys.orders.byIntegration(integrationId), entityId] as const,
  },
  oauth2: {
    all: () => ["oauth2"] as const,
    byPortalId: (portalId?: number) => [...QueryKeys.oauth2.all(), portalId ?? "__UNKNOWN__"] as const,
  },
}

export default QueryKeys
