import useCore from "@/plugins/use-core"
import { onMounted, ref } from "vue"

export type Publishing = {
  id: number
  portalId: number
  publishDate: string
  postUrl: string
  externalId: string
  portal: {
    format: string
    parentConnectionId: number
  }
}

export const useLastPublishedPortal = (propertyId?: number) => {
  const { graphql } = useCore()
  const lastPublishedPortal = ref<Publishing | null>(null)

  const fetchData = async () => {
    if (!propertyId) {
      return
    }

    const response = await graphql(`query propertyForm {
      property(id: ${propertyId}) {
      publishings { portalId publishDate portal { format parentConnectionId } }
      }
    }`)

    lastPublishedPortal.value =
      response.property?.publishings
        .filter(item => !item.portal.parentConnectionId && item.portal.format === "IS24")
        .sort((a, b) => {
          return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
        })[0] || null
  }

  onMounted(() => {
    fetchData()
  })

  return { lastPublishedPortal, fetchData }
}
