import { debounce } from "debounce"
import { set, get, del } from "idb-keyval"
import { cloneDeep } from "lodash"

export function pickBy(object, fn) {
  const obj = {}
  for (const key in object) {
    if (fn(key, object[key])) {
      obj[key] = object[key]
    }
  }
  return obj
}

export function saveStateToLocalStorage(key, data) {
  try {
    localStorage.setItem(key, JSON.stringify(data))
  } catch (e) {
    console.warn("Error saving state to local storage. Clearing localStorage!", e)
    // this is probably due to the storage being full
    localStorage.clear()
  }
}

export function removeState(key) {
  localStorage.removeItem(key)
}

export function getSavedStateFromLocalStorage(key) {
  var savedState = localStorage.getItem(key)
  if (!savedState) return null
  try {
    return JSON.parse(savedState)
  } catch (e) {
    console.warn("Error parsing saved state from local storage!", e)
    localStorage.removeItem(key)
    return null
  }
}

export async function saveStateToIndexedDb(key, data) {
  // uses cloneDeep to remove reactive proxies before serializing, otherwise IDB can not serialize nested structures
  const rawData = cloneDeep(data)
  return set(key, rawData).catch(err => console.log("Cache set operation is failed!", err))
}

export async function removeStateFromIndexedDb(key) {
  return del(key).catch(err => console.log("Cache delete operation is failed!", err))
}

export async function getSavedStateFromIndexedDb(key) {
  return get(key).catch(err => console.log("Cache get operation is failed!", err))
}

export default {
  watch: {
    $data: {
      handler: function () {
        this.debouncedHandler()
      },
      deep: true,
    },
  },
  data() {
    return {
      debouncedHandler: undefined,
    }
  },
  created() {
    this.debouncedHandler = debounce(function () {
      this.saveState()
    }, 25)
    this.loadState()
  },
  methods: {
    loadState: function () {
      if (!this.saveStateConfig) return

      var savedState = getSavedStateFromLocalStorage(this.cacheKey)
      if (!savedState) return

      const restoredStateObject = Object.entries(savedState).reduce((acc, [key, value]) => {
        if (!this.attributeIsManagedBySaveState(key)) return acc
        acc[key] = this.saveStateConfig.onLoad?.(key, value) ?? value
        return acc
      }, {})

      if (!Object.keys(restoredStateObject).length) return

      // NOTE: should be used with caution, as it can trigger unwanted side effects
      this.$withoutWatchers(() => {
        for (const key in restoredStateObject) {
          this.$data[key] = restoredStateObject[key]
        }
      })
    },

    saveState: function () {
      const data = pickBy(this.$data, attr => this.attributeIsManagedBySaveState(attr))
      saveStateToLocalStorage(this.cacheKey, data)
    },

    attributeIsManagedBySaveState: function (attribute) {
      const config = this.saveStateConfig

      if (config.excludeProperties) return config.excludeProperties.includes(attribute)

      if (!config.saveProperties) return true

      return config.saveProperties.includes(attribute)
    },

    removeState: function () {
      removeState(this.cacheKey)
    },
  },

  computed: {
    saveStateConfig() {
      return {
        ...this.$options.saveStateConfig,
        cacheKey: `${this.$options.saveStateConfig.cacheKey}-${this.$db.broker.id}`,
      }
    },
    cacheKey() {
      return this.saveStateConfig.cacheKey
    },
  },
}
