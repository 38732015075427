import { FormField, FormFieldType } from "../../interfaces"

const pLabelOptions = (fieldName: string) => {
  return ({ db }) => {
    const mapping = db.shopData.propertyOptionsMapping[fieldName]
    return Object.keys(mapping).map(key => ({ id: key, name: mapping[key] }))
  }
}

const FORM_FIELDS: Record<string, FormField> = {
  clientId: {
    group: "general",
    component: "client-dropdown",
  },
  propertyId: {
    group: "general",
    component: "property-dropdown",
  },
  projectId: {
    dbOptions: "projects",
    type: FormFieldType.dropdown,
    group: "general",
    filterable: true,
  },
  dealStageId: {
    dbOptions: "groupedDealStages",
    component: "dbDropdown",
    group: "general",
  },
  brokerId: {
    dbOptions: "activeBrokers",
    type: FormFieldType.dropdown,
    group: "supervisor",
    filterable: true,
    filterName: "responsible_broker",
    readonly: ({ db, record }) => record && record.brokerId != db.broker.id && !db.broker.canChangeBroker,
  },
  brokerIds: {
    type: FormFieldType.multiselect,
    dbOptions: "brokers",
    notForLp: true,
    group: "general",
    filterable: ({ db }) => db.broker.canSeeAllClients && db.shopData.departments.length,
  },
  departmentIds: {
    type: FormFieldType.multiselect,
    dbOptions: "departments",
    notForLp: true,
    group: "general",
    filterable: ({ db }) => db.broker.canSeeAllClients && db.shopData.departments.length,
  },
  startDate: {
    type: FormFieldType.date,
    group: "general",
  },
  soldPrice: {
    type: FormFieldType.number,
    unit: "currency",
    group: "general",
  },
  clientSourceId: {
    dbOptions: "clientSources",
    type: FormFieldType.dropdown,
    group: "general",
  },
  feeling: {
    type: FormFieldType.slider,
    group: "general",
    step: 1,
    max: 100,
  },
  note: {
    type: FormFieldType.text,
    group: "general",
  },
  teamId: {
    dbOptions: "teams",
    type: FormFieldType.dropdown,
    group: "general",
  },
  is24UserProfileUrl: {
    group: "general",
    type: FormFieldType.string,
  },
  buyReason: {
    group: "general",
    type: FormFieldType.string,
  },
  homeOwner: {
    group: "general",
    type: FormFieldType.boolean,
  },
  employmentStatus: {
    group: "general",
    type: FormFieldType.string,
  },
  numberOfPersons: {
    group: "general",
    type: FormFieldType.string,
  },
  ownCapital: {
    group: "general",
    type: FormFieldType.string,
  },
  householdNetIncomeRange: {
    group: "general",
    type: FormFieldType.string,
  },
  maxBudget: {
    group: "general",
    type: FormFieldType.number,
    unit: "currency",
  },
  searchAdvancePhase: {
    group: "general",
    type: FormFieldType.dropdown,
    options: pLabelOptions("search_advance_phase"),
  },
  financingStatus: {
    group: "general",
    type: FormFieldType.dropdown,
    options: pLabelOptions("financing_status"),
  },
  preApproval: {
    group: "general",
    type: FormFieldType.dropdown,
    options: pLabelOptions("pre_approval"),
    visible: db => db.shopData.allEnabledExtensions?.includes("justhome") ?? false,
  },
}

export default FORM_FIELDS
