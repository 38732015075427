<template>
  <main class="w-100">
    <header class="mainHeader" :style="{ background: color }"></header>
    <section class="flex">
      <div style="width: 235px; height: calc(100vh - 44px); background-color: #f9fafb"></div>
      <div class="p-4 flex flex-col">
        <div class="shine mb-4" style="width: 800px"></div>
        <div class="shine" style="width: 550px"></div>
        <div class="shine"></div>
        <div class="shine" style="width: 600px"></div>
      </div>
    </section>
  </main>
</template>

<script>
import { getSavedStateFromIndexedDb } from "@/mixins/save-state-mixin"
import { GLOBAL_SHOP_DATA_KEY } from "@/config/constants"

export default {
  color: "#0f55eb",
  mounted() {
    this.getBrokerColor()
  },
  methods: {
    async getBrokerColor() {
      const state = await getSavedStateFromIndexedDb(GLOBAL_SHOP_DATA_KEY)
      if (state.broker.headerColor) {
        this.color = state.broker.headerColor
      }
    },
  },
}
</script>

<style scoped>
.shine {
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 30px;
  display: inline-block;

  margin: 10px 0 10px 10px;
  border-radius: 5px;
  background: linear-gradient(90deg, #0000 33%, rgba(255, 255, 255, 0.3) 50%, #0000 66%) rgb(222, 222, 222);
  background-size: 300% 100%;
  animation: shine 1s infinite;
}

@keyframes shine {
  0% {
    background-position: right;
  }
}
</style>
