<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import NotFound from "../NotFound.vue"
import Spinner from "@/components/Spinner.vue"
import { useLastPublishedPortal } from "@/composables/use-last-published-portal"
import { useFetchAuth2Token } from "@/composables/use-is24-oauth2-token"
import eventBus from "@/config/event-bus"
import config from "@/config/config"
import { useRoute } from "vue-router"
import useCore from "@/plugins/use-core"

const props = defineProps<{
  scoutPropertyId: number
  propertyId: number
  conversationId?: string
  hasCloseButton?: boolean
}>()

const isLoading = ref(true)
const iframeRef = ref<HTMLIFrameElement | null>(null)
const isInvalid = ref<boolean>(!props.scoutPropertyId)

const { lastPublishedPortal } = useLastPublishedPortal(props.propertyId)
const { fetchToken } = useFetchAuth2Token()

const route = useRoute()
const { api } = useCore()

const scoutUrl = computed(() => {
  return config.ENV_TYPE === "pro"
    ? "https://www.immobilienscout24.de/nachrichten-manager/crm-iframe/"
    : "https://www.sandbox-immobilienscout24.de/nachrichten-manager/crm-iframe/"
})

const conversationPath = computed(() => `conversation-list/${props.scoutPropertyId}/inbox/conversations`)
const messagePath = computed(() => {
  return `single-conversation/${props.scoutPropertyId}/inbox/messages/${props.conversationId}`
})

const source = computed(() => {
  const path = props.conversationId ? messagePath.value : conversationPath.value

  return scoutUrl.value + path
})

const handleOnLoad = async () => {
  const token = await fetchToken(lastPublishedPortal.value?.portalId)
  isLoading.value = false

  // await api.mutation("createProductTracking", {
  //   eventType: "scout_messenger_event",
  // })

  if (!token) {
    console.error("token is not available")
    isInvalid.value = true
    return
  }

  if (!iframeRef.value?.contentWindow) {
    console.error("iframeRef contentWindow is not available")
    isInvalid.value = true
    return
  }

  iframeRef.value.contentWindow.postMessage(
    {
      type: "AUTH_TOKEN",
      token: token,
    },
    source.value
  )

  watch(
    () => route.fullPath,
    (_newPath, _oldPath) => {
      eventBus.$emit("quick-view@close")
    }
  )

  onMounted(() => {
    api.mutation("createProductTracking", {
      eventType: "scout_messenger_event",
    })
  })
}
</script>
<template>
  <page-header>
    <template v-slot:leftMenu>
      <a href="#" @click.prevent="$emit('close')" class="pane-button">
        <fa-icon name="times" />
      </a>
    </template>
    <template v-slot:rightButtons>
      <div class="border-blue-600 border-b-4 text-center w-40">
        <h2 class="pageHeader-title">{{ $t("integrations.extension-scout-messenger.messenger") }}</h2>
      </div>
    </template>
  </page-header>
  <Spinner v-if="isLoading" />
  <div class="w-full h-[calc(100vh-87px)]">
    <div v-if="isInvalid">
      <div class="pt-8">
        <NotFound
          :callback="undefined"
          :button-text="''"
          :desription-text="'Hoppla! Das hätte nicht passieren dürfen.'"
          :header-text="''"
        />
      </div>
    </div>
    <iframe
      v-else-if="!isInvalid"
      :class="{ hidden: isLoading }"
      class="aspect-square"
      ref="iframeRef"
      width="100%"
      height="100%"
      frameborder="0"
      :src="source"
      allowfullscreen
      @load="handleOnLoad"
    />
  </div>
</template>
