import { propstackIntegrationMapping } from "@/config/extensions"
import { unpack } from "@/utils/imports"
import { MaybeRef, get } from "@vueuse/core"
import { computed } from "vue"
import { IntegrationMapping, TranslationFunction } from "./types/integration-page"
import {
  ExtensionScoutMessenger,
  IntegrationChatGPT,
  IntegrationId,
  IntegrationMcGrundriss,
  IntegrationWhatsapp,
} from "./types/integration-service"
import { useDefaultConfiguration } from "./use-default-configuration"
import { useI18n } from "vue-i18n"

export const useIntegrationMapping = (integrationId: MaybeRef<IntegrationId>) => {
  const { t } = useI18n()
  return computed(() => getIntegrationMapping(get(integrationId), t))
}

export const getIntegrationMapping = (
  id: IntegrationId,
  t: TranslationFunction
): IntegrationMapping<IntegrationChatGPT | IntegrationMcGrundriss | IntegrationWhatsapp> => {
  // these contain custom configuration components and configuration hooks
  const IntegrationMapper = {
    // "integration-chatgpt-ps": {
    //   useConfiguration: useDefaultConfiguration,
    //   name: t("integrations.integration-chatgpt.name"),
    //   caption: t("integrations.configuration.title", { name: t("integrations.integration-chatgpt.name") }),
    //   description: t("integrations.integration-chatgpt.description"),
    //   summary: t("integrations.integration-chatgpt.summary"),
    //   consentText: t("integrations.integration-chatgpt.consentText"),
    //   customPermissions: [
    //     t("integrations.integration-chatgpt.permissions.email"),
    //     t("integrations.integration-chatgpt.permissions.estate"),
    //   ],
    //   imageUrl: CloudResourcesUtil.getIntegrationLogoUrl("integration-chatgpt.png"),
    // } as IntegrationMapping<IntegrationChatGPT>,
    "integration-mcgrundriss-ps": {
      useConfiguration: useDefaultConfiguration,
      name: t("integrations.integration-mcgrundriss.name"),
      caption: t("integrations.configuration.title", { name: t("integrations.integration-mcgrundriss.caption") }),
      description: t("integrations.integration-mcgrundriss.description"),
      summary: t("integrations.integration-mcgrundriss.summary"),
      consentText: t("integrations.integration-mcgrundriss.consentText"),
      imageUrl: unpack(
        import.meta.glob("@/assets/images/extension/integration-mcgrundriss.jpg", {
          query: "?url",
          import: "default",
          eager: true,
        })
      ),
    } as IntegrationMapping<IntegrationMcGrundriss>,
    "integration-whatsapp-ps": {
      useConfiguration: useDefaultConfiguration,
      name: t("integrations.integration-whatsapp.name"),
      description: t("integrations.integration-whatsapp.description"),
      summary: t("integrations.integration-whatsapp.summary"),
      consentText: t("integrations.integration-whatsapp.consentText"),
      imageUrl: unpack(
        import.meta.glob("@/assets/images/extension/whatsapp.svg", { query: "?url", import: "default", eager: true })
      ),
    } as IntegrationMapping<IntegrationWhatsapp>,
    "extension-scout-messenger-ps": {
      useConfiguration: useDefaultConfiguration,
      name: t("integrations.extension-scout-messenger.name"),
      description: t("integrations.extension-scout-messenger.description"),
      summary: t("integrations.extension-scout-messenger.summary"),
      consentText: t("integrations.extension-scout-messenger.consentText"),
      imageUrl: unpack(
        import.meta.glob("@/assets/images/extension/scout-messenger.png", {
          query: "?url",
          import: "default",
          eager: true,
        })
      ),
    } as IntegrationMapping<ExtensionScoutMessenger>,
  } as const

  Object.assign(IntegrationMapper, propstackIntegrationMapping(t))
  return IntegrationMapper[id] // ?? ({ useConfiguration: useDefaultConfiguration } as IntegrationMapping<Integration>) // unknown integrations should use the default configuration
}
