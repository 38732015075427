import axiosInstance from "@/config/axios"
import { AxiosRequestConfig, AxiosResponse, CancelToken } from "axios"
import { stringify } from "qs"
import { APIService } from "./api-mapping"
import { translateStatusCode } from "./status-codes"

export type ParamMap = { [key: string]: string | boolean | number | undefined }

export interface APIClientAdditionalParams extends Omit<AxiosRequestConfig, "headers"> {
  headers?: string | ParamMap
  queryParams?: ParamMap
  cancelToken?: CancelToken
}

export type MethodTypes = "GET" | "POST" | "DELETE" | "PUT" | "OPTIONS" | "PATCH" | "HEAD"

export type APIVersion = string

export class APIClient {
  private readonly _service: APIService | undefined

  public constructor(service?: APIService, version?: APIVersion) {
    this._service = service
  }

  public get baseUrl() {
    return this._service?.name ?? ""
  }
  /**
   * @deprecated Use invokeApiWithErrorHandling instead.
   */
  public async invokeApi<T = any>(
    path: string,
    method: MethodTypes = "GET",
    body: unknown | string | Record<string, unknown> = "",
    additionalParams: APIClientAdditionalParams = {}
  ): Promise<AxiosResponse<T>> {
    // If no service is defined and the url does not start with http, then we throw an error

    const { queryParams, headers, cancelToken, ...others } = additionalParams
    let apiUrl = `/${this.baseUrl}${path}`
    if (queryParams) {
      const queryString = stringify(queryParams, { addQueryPrefix: true })
      if (queryString && queryString !== "") {
        apiUrl += queryString
      }
    }

    const request: AxiosRequestConfig = {
      method: method,
      url: apiUrl,
      headers: Object.assign({}, headers || {}),
      data: body,
      cancelToken: cancelToken,
      ...others,
    }

    // fire the request
    // NEVER put a catch here because it prevents all other error handling
    // i.e. you can't handle a service returning an http code >= 400 (which is possibly expected)
    return axiosInstance.request<T>(request)
  }

  public async invokeApiWithErrorHandling<T = any, E = any>(
    path: string,
    method: MethodTypes = "GET",
    body: unknown | string | Record<string, unknown> = "",
    additionalParams: APIClientAdditionalParams = {}
  ): Promise<ApiResponse<T, E>> {
    try {
      const response: AxiosResponse<T> = await this.invokeApi<T>(path, method, body, additionalParams)

      const isSuccessful2xx = response.status >= 200 && response.status < 300

      return {
        isSuccessful2xx,
        ...response,
        // response.data can also be '' for 204 for example. We always wants to return undefined in these cases!
        data: response.data || (typeof response.data === "boolean" ? response.data : undefined),
        translatedStatusMessage: isSuccessful2xx ? undefined : translateStatusCode(response.status),
      } as ApiSuccessResponse<T>
    } catch (error: any) {
      // only log 500er range errors, the rest is assume correct API behavior
      const is5xx = Number(error.response?.status) >= 500

      if (is5xx) {
        console.error(error)
      }

      return {
        isSuccessful2xx: false,
        data: undefined,
        status: 0,
        translatedStatusMessage: translateStatusCode(error?.response?.status),
        // be on the safe side, when it is not a AxiosError.
        // The status and data can be included in error.response, so it will be maybe overwritten
        ...(error?.response || {}),
      } as ApiErrorResponse<E>
    }
  }
}

export interface ApiSuccessResponse<T = unknown> extends AxiosResponse<T> {
  isSuccessful2xx: true
  translatedStatusMessage?: string
}

export interface ApiErrorResponse<T = unknown> extends AxiosResponse<T> {
  isSuccessful2xx: false
  translatedStatusMessage?: string
}

export type ApiResponse<T = any, E = any> = ApiSuccessResponse<T> | ApiErrorResponse<E>

// type guards
export const isSuccessResponse = <T, E>(response: ApiResponse<T, E>): response is ApiSuccessResponse<T> =>
  response.isSuccessful2xx
export const isErrorResponse = <T, E>(response: ApiResponse<T, E>): response is ApiErrorResponse<E> =>
  !response.isSuccessful2xx
